<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col>
          <h5 class="text-h5 text-typo font-weight-bold mb-0">
            Pallet Perks Loyalty Program
          </h5>
          <div class="d-flex">
            <p class="text-sm text-body mb-0">
              Join Pallet Perks and start earning delicious rewards! Get a 25
              point welcome bonus and earn 1 point for every $1 spent and
              receive a $5 discount for every 100 points you redeem. Present
              your Loyalty Code to the bartender at checkout to link and apply
              your points account.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" md="6" lg="3" cols="12">
          <v-card class="card-shadow border-radius-xl px-4 py-4">
            <v-row no-gutters>
              <v-col sm="8">
                <p
                  class="
                    text-sm
                    mb-0
                    text-capitalize
                    font-weight-bold
                    text-body
                  "
                >
                  Signup Bonus
                </p>
                <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                  25 Points
                </h5>
              </v-col>
              <v-col sm="4" class="text-end">
                <v-avatar color="bg-gradient-success" class="shadow" rounded>
                  <v-icon size="20" class="text-white"> fa fa-coins </v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col xs="12" md="6" lg="3" cols="12">
          <v-card class="card-shadow border-radius-xl px-4 py-4">
            <v-row no-gutters>
              <v-col sm="8">
                <p
                  class="
                    text-sm
                    mb-0
                    text-capitalize
                    font-weight-bold
                    text-body
                  "
                >
                  Sun-Tues
                </p>
                <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                  Double Points
                </h5>
              </v-col>
              <v-col sm="4" class="text-end">
                <v-avatar color="bg-gradient-success" class="shadow" rounded>
                  <v-icon size="20" class="text-white">
                    fa fa-check-double
                  </v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col xs="12" md="6" lg="3" cols="12">
          <v-card class="card-shadow border-radius-xl px-4 py-4">
            <v-row no-gutters>
              <v-col sm="8">
                <p
                  class="
                    text-sm
                    mb-0
                    text-capitalize
                    font-weight-bold
                    text-body
                  "
                >
                  Takeout Orders
                </p>
                <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                  Double Points
                </h5>
              </v-col>
              <v-col sm="4" class="text-end">
                <v-avatar color="bg-gradient-success" class="shadow" rounded>
                  <v-icon size="20" class="text-white">
                    fa fa-check-double
                  </v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col xs="12" md="6" lg="3" cols="12">
          <v-card class="card-shadow border-radius-xl px-4 py-4">
            <v-row no-gutters>
              <v-col sm="8">
                <p
                  class="
                    text-sm
                    mb-0
                    text-capitalize
                    font-weight-bold
                    text-body
                  "
                >
                  Birthday Month
                </p>
                <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                  $5 Credit
                </h5>
              </v-col>
              <v-col sm="4" class="text-end">
                <v-avatar color="bg-gradient-success" class="shadow" rounded>
                  <v-icon size="20" class="text-white">
                    fa fa-birthday-cake
                  </v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" md="6" cols="12">
          <v-card
            href="https://www.toasttab.com/the-pallet/rewardsSignup"
            target="_blank"
            class="bg-gradient-warning border-radius-xl"
          >
            <div class="px-4 py-4">
              <v-row class="row">
                <v-col cols="12" class="my-auto">
                  <div class="numbers">
                    <center>
                      <h4 class="text-white text-h5 font-weight-bolder mb-0">
                        IT'S EASY. SIGN UP NOW.
                      </h4>
                    </center>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col xs="12" md="6" cols="12">
          <v-card
            href="https://www.toasttab.com/the-pallet/rewards"
            target="_blank"
            class="bg-gradient-warning border-radius-xl"
          >
            <div class="px-4 py-4">
              <v-row class="row">
                <v-col cols="12" class="my-auto">
                  <div class="numbers">
                    <center>
                      <h4 class="text-white text-h5 font-weight-bolder mb-0">
                        CHECK YOUR POINTS BALANCE.
                      </h4>
                    </center>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "PalletPerks",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
